import { AppConfig, Coupon } from 'src/types';
import { Config } from './config';
import EmailChk from '@dintero/email-chk';

const urlRegex = new RegExp(/^(?:http(?:s)?:\/\/)(?!((?:[\w\S])*(?::\/\/)))(?:www\.)?(?:[\w\S])+\.(?:[\w\S])+$/);

export type VippsLoginResponseBody = {
    access_token: string,
    user_info: {
        value: {
            email: string,
            phone_number: string,
            given_name: string,
            family_name: string,
            address: {
                street_address: string,
                postal_code: string,
                region: string,
                country: string,
            }
        }
    }
};

class Utils {
    static parseJwt( token: string ) {
        try {
            var base64Url = token.split('.')[1];
            // var base64 = base64Url.replace('-', '+').replace('_', '/');
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            return JSON.parse(window.atob(base64));
        } catch (e) {
            return null;
        }
    }

    static validToken( token: string ): boolean {
        const jwtToken = Utils.parseJwt(token);
        if (jwtToken !== null && !isNaN(jwtToken.exp)) {
            const date = new Date;
            const dateNow = date.getTime();
            const unixTimeNow = Math.floor(dateNow / 1000);
            return jwtToken.exp > unixTimeNow;
        }
        return false;
    }

    static getCustomerIdFromAccessToken(accessToken?: string) {
        const customerBearerToken = accessToken || sessionStorage.getItem('customer_access_token');
        if (customerBearerToken !== null) {
            const customerAccessToken = this.parseJwt(customerBearerToken);
            return customerAccessToken.sub;
        }
        return null;
    }

    static getLocationFromPostalCode( postalCode: string ) {
        return new Promise(( resolve, reject ) => {
            return fetch('https://adressesok.posten.no/api/v1/postal_codes.json?postal_code=' + postalCode, {
                method: 'GET',
                headers: new Headers({
                    'Accept': 'application/json'
                })
            }).then(function ( response: Response ) {
                return response.json();
            }).then(( json: any ) => {
                if (json.postal_codes !== undefined) {
                    let city = json.postal_codes[0].city;
                    let firstLetter = city.slice(0, 1);
                    city = firstLetter.toUpperCase() + city.substring(1).toLowerCase();
                    let response = '';
                    if (city !== '') {
                        response = city;
                    }
                    resolve(response);
                } else {
                    resolve('');
                }
                return json;
            }).catch(( ex: JSON ) => {
                reject(new Error('Kunne ikke hente sted fra postnummer. ' + JSON.stringify(ex)));
            });
        });
    }

    static getCompanyInfoFromOrgNr( organizationNumber: string ) {
        return new Promise(( resolve, reject ) => {
            return fetch('https://hotell.difi.no/api/json/brreg/enhetsregisteret?query=' + encodeURIComponent(organizationNumber), {
                method: 'GET',
                headers: new Headers({
                    'Accept': 'application/json'
                })
            }).then(function ( response: Response ) {
                return response.json();
            }).then(( json: any ) => {
                let companyInfo: any = {
                    'bussiness_name': '',
                    'address_line': '',
                    'postal_code': '',
                    'postal_place': '',
                    'industry': '',
                    'number_of_employees': '',
                };
                if (json.entries !== undefined && json.entries.length > 0) {
                    if (json.entries[0].navn !== undefined) {
                        companyInfo.bussiness_name = json.entries[0].navn;
                    }
                    if (json.entries[0].forretningsadr !== undefined) {
                        companyInfo.address_line = json.entries[0].forretningsadr;
                    }
                    if (json.entries[0].forradrpostnr !== undefined) {
                        companyInfo.postal_code = json.entries[0].forradrpostnr;
                    }
                    if (json.entries[0].forradrpoststed !== undefined) {
                        companyInfo.postal_place = json.entries[0].forradrpoststed;
                    }
                    if (json.entries[0].nkode1 !== undefined) {
                        companyInfo.industry = json.entries[0].nkode1;
                    }
                    if (json.entries[0].ansatte_antall !== undefined) {
                        companyInfo.number_of_employees = json.entries[0].ansatte_antall;
                    }
                }
                resolve(companyInfo);
                return json;
            }).catch(( ex: JSON ) => {
                reject(new Error('Kunne ikke hente firmainfo fra orgnr. ' + JSON.stringify(ex)));
            });
        });
    }

    static formatOrgnummer( orgNummer: string ) {
        if (!orgNummer) {
            return '';
        }
        let nummer: string = orgNummer.substr(0, 9);
        let extra: string = '';
        if (orgNummer.length > 9) {
            extra = ' ' + orgNummer.substr(9, orgNummer.length);
        }
        return 'Org ' + nummer.replace(/\B(?=(.{3})+(?!.))/g, '\u00A0') +
            extra;
    }

    static formatPrice( price: number ) {
        if (isNaN(price)) {
            return '';
        }
        let p: string = (price / 100).toFixed(2);
        let parts = p.split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0');
        return parts.join(',');
    }

    static formatTimestamp( isoDate: string ) {
        let ts = isoDate.split(/((\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\w+)?)/);
        return ts[4] + '.' + ts[3] + '.' + ts[2] + ' ' + ts[5] + ':' + ts[6];
    }

    static generateEan13( receiptId: string ) {
        if (!receiptId) {
            return false;
        }
        // Set GS1 prefix to 200
        let code: string = '200' + this.padEnd(receiptId, '0', 9);

        let weightFlag: boolean = true;
        let sum: number = 0;
        for (let i = code.length - 1; i >= 0; i--) {
            sum += Number(code.substr(i, 1)) * (weightFlag ? 3 : 1);
            weightFlag = !weightFlag;
        }
        code += ((10 - (sum % 10)) % 10);
        return code;
    }

    static padEnd( source: string, padString: string, length: number ) {
        while (source.length < length) {
            source = padString + source;
        }
        return source;
    }

    static getBase64Image( img ) {
        let canvas = document.createElement('canvas');
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        let ctx = canvas.getContext('2d');
        if (ctx !== null) {
            ctx.drawImage(img, 0, 0);
        }
        var dataURL = canvas.toDataURL('image/png');
        return dataURL;
    }

    static getAccessToken = ( config, customerConfig ): Promise<string | null> => {
        const bearerToken = sessionStorage.getItem('access_token');
        if (bearerToken !== null && Utils.validToken(bearerToken)) {
            return Promise.resolve(bearerToken);
        }

        if (!customerConfig.API_KEY || !customerConfig.API_SECRET || !customerConfig.AID) {
            throw 'Invalid API_KEY or API_SECRET or AID';
        }

        const encodedString = new Buffer(
            customerConfig.API_KEY + ':' + customerConfig.API_SECRET
        ).toString('base64');

        return fetch(
            config.urlApi + 'accounts/' + customerConfig.AID + '/auth/token', {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Basic ' + encodedString,
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
                audience: config.audience_prefix + customerConfig.AID,
                grant_type: 'client_credentials',
                verification_code: 'string'
            })
        }).then(function ( response: Response ) {
            return response.json();
        }).then(( json: any ) => {
            sessionStorage.setItem('access_token', json.access_token);
            return sessionStorage.getItem('access_token');
        }).catch(function ( ex: JSON ) {
            console.log('parsing failed', ex);
            return null;
        });
    }

    static fetchWithAccessToken( url: string, init: RequestInit, config: Config, customerConfig: AppConfig): Promise<Response> {
        return this.getAccessToken(config, customerConfig).then(accessToken => {
            if (!accessToken) {
                return Promise.reject('Failed to get access token');
            }
            init.headers = new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
            });
            return fetch(url, init);
        });
    }

    static isUrl (url: string) {
        return urlRegex.test(url);
    }

    static normalizeVippsPhoneNumber (phoneNumber: string) {
        // handle vipps phone number without leading plus
        return phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;
    }

    static getEnrolledBy () {
        const queryParams = new URLSearchParams(window.location.search);
        const validTypes = ['url', 'store', 'custom'];
        const type = queryParams.get('enrolled_by_type');
        const value = queryParams.get('enrolled_by_value');
        if (type && validTypes.includes(type) && value) {
            return {
                type,
                value
            };
        }
        return {
            type: 'url',
            value: window.location.protocol + '//' + window.location.hostname
        };
    }

    static getVippsScopes(customerConfig: AppConfig) {
        const scopes = ['openid'];
        const fields = customerConfig.customer_fields;
        if (fields.phone_number.required) {
            scopes.push('phoneNumber');
        }
        if (fields.email.required) {
            scopes.push('email');
        }
        if (fields.address_line.required || fields.postal_code.required || fields.postal_place.required) {
            scopes.push('address');
        }
        if (fields.first_name.required || fields.last_name.required) {
            scopes.push('name');
        }
        return scopes.join('+');
    }

    static async initiateVippsLogin(config: Config, customerConfig: AppConfig): Promise<void> {
        // store vipps state variable in localStorage so we can check if it matches after redirect
        const state = this.generateId();
        this.safeLocalStorageSet('vipps-login-state', state);
        // store enrolled by in localStorage so we can set it after redirecting back from vipps
        this.safeLocalStorageSet('vipps-login-enrolled-by', JSON.stringify(this.getEnrolledBy()));

        try {
            // go to vipps login via redirect from the Dintero api
            this.getAccessToken(config, customerConfig).then(accessToken => {
                const params = Object.entries({
                    audience: this.getApiUrl(config, customerConfig, 'vipps-login'),
                    response_type: 'code',
                    client_id: customerConfig.API_KEY,
                    redirect_uri: `${window.location.origin}/vipps-login`,
                    scope: this.getVippsScopes(customerConfig),
                    state: state,
                    client_token: accessToken
                }).map(entry => entry.join('=')).join('&');
                const redirectUrl = this.getApiUrl(config, customerConfig, `auth/oidc/authorize?${params}`);
                window.location.href = redirectUrl;
            });
        } catch (err) {
            console.log('fetch error', err);
        }
    }

    static getApiUrl(config, customerConfig, trail: string): string {
        return `${config.urlApi}accounts/${customerConfig.AID}/${trail}`;
    }

    static async userExists(config: Config, customerConfig: AppConfig, tokenResponse: any): Promise<boolean> {
        const accessToken = tokenResponse.access_token;
        const sub = this.getCustomerIdFromAccessToken(accessToken);
        const response = await fetch(
            this.getApiUrl(config, customerConfig, `customers/users/${sub}`),
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                }
            }
        );
        const body = await response.json();
        return response.status === 200 && !body.deleted_at;
    }

    static async putUserFromVippsLogin(
        config: Config, customerConfig: AppConfig, loginResponseBody: VippsLoginResponseBody, user
    ): Promise<{success: boolean, json: any}> {
        try {
            const accessToken = loginResponseBody.access_token;
            const sub = this.getCustomerIdFromAccessToken(accessToken);
            const response = await fetch(
                this.getApiUrl(config, customerConfig, `customers/users/${sub}`),
                {
                    method: 'PUT',
                    headers: {
                        'Authorization': 'Bearer ' + accessToken,
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    body: JSON.stringify({
                        ...user,
                    })
                },
            );
            const json = await response.json();
            return {success: response.status === 200, json};
        } catch (e) {
            return {success: false, json: {error: {message: e.message}}};
        }
    }

    static async postUserFromVippsLogin(
        config: Config, customerConfig: AppConfig, loginResponseBody: VippsLoginResponseBody, user
    ): Promise<{success: boolean, json: any}> {
        try {
            const accessToken = loginResponseBody.access_token;
            const sub = this.getCustomerIdFromAccessToken(accessToken);
            const response = await this.fetchWithAccessToken(
                this.getApiUrl(config, customerConfig, `customers/users/`),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    body: JSON.stringify({
                        customer_id: sub,
                        ...user,
                    })
                },
                config,
                customerConfig,
            );
            const json = await response.json();
            return {success: response.status === 200, json};
        } catch (e) {
            return {success: false, json: {error: {message: e.message}}};
        }
    }

    static async createUserFromVippsLogin(
        config: Config, customerConfig: AppConfig, loginResponseBody: VippsLoginResponseBody, user
    ): Promise<{success: boolean, json: any}> {
        // If the user was deleted, the GET request to check if the user exists will return 404,
        // but posting an new user will fail, so the deleted user will have to be updated via a
        // PUT request.
        const postResult = await this.postUserFromVippsLogin(config, customerConfig, loginResponseBody, user);
        if (!postResult.success) {
            return this.putUserFromVippsLogin(config, customerConfig, loginResponseBody, user);
        }
        return postResult;
    }

    static mapVippsLoginResponseDataToUser (loginResponseBody: VippsLoginResponseBody, enrolledBy: any) {
        return {
            email: loginResponseBody?.user_info.value.email,
            phone_number: this.normalizeVippsPhoneNumber(loginResponseBody.user_info.value.phone_number),
            first_name: loginResponseBody.user_info.value.given_name,
            last_name: loginResponseBody.user_info.value.family_name,
            addresses: loginResponseBody.user_info.value.address?.street_address ? [{
                address_line: loginResponseBody.user_info.value.address?.street_address,
                postal_code: loginResponseBody.user_info.value.address?.postal_code,
                postal_place: loginResponseBody.user_info.value.address?.region,
                country: loginResponseBody.user_info.value.address?.country,
            }] : undefined,
            enrolled_by: enrolledBy || undefined,
        };
    }

    static async handleVippsRedirect(config: Config, customerConfig: AppConfig): Promise<
        void |
        {
            login_response: VippsLoginResponseBody,
            event: 'login' | 'register_user',
            enrolled_by: any
        }
    > {
        const urlParams = new URLSearchParams(window.location.search);
        const state = urlParams.get('state');
        if (state !== this.safeLocalStorageGet('vipps-login-state')) {
            console.error(
                'vipps state mismatch',
                {
                    local: this.safeLocalStorageGet('vipps-login-state'),
                    redirect: state
                }
            );
            return;
        }
        const code = urlParams.get('code');
        const loginResponse = await this.fetchWithAccessToken(
            this.getApiUrl(config, customerConfig, 'auth/oidc/token'),
            {
                method: 'POST',
                body: JSON.stringify({
                    audience: this.getApiUrl(config, customerConfig, `vipps-login`),
                    code: code,
                    client_id: customerConfig.API_KEY,
                    redirect_uri: window.location.origin + '/vipps-login',
                    grant_type: 'authorization_code'
                })
            },
            config,
            customerConfig
        );
        const loginResponseBody = await loginResponse.json() as VippsLoginResponseBody;
        if (loginResponse.status !== 200 || !loginResponseBody.access_token) {
            console.error('invalid vipps login response', {
                status: loginResponse.status,
                body: loginResponseBody,
            });
            return;
        }
        const userExists = await this.userExists(config, customerConfig, loginResponseBody);
        const enrolledBy = JSON.parse(this.safeLocalStorageGet('vipps-login-enrolled-by') || 'null');
        return {
            'login_response': loginResponseBody,
            'event': userExists ? 'login' : 'register_user',
            'enrolled_by': enrolledBy
        };
    }

    static getTerms( config: Config, customerConfig: AppConfig) {
        return this.fetchWithAccessToken(
            config.urlApi + 'accounts/' + customerConfig.AID + '/customers/terms',
            {
                method: 'GET',
            },
            config, customerConfig
        ).then(function ( response: Response ) {
            return response.json();
        }).then(( json: any ) => {
            if (json.error === undefined) {
                return json;
            } else {
                return null;
            }
        }).catch(( err ) => {
            console.log('error getting terms', err);
            return null;
        });
    }

    static getStores( config: Config, customerConfig: AppConfig) {
        return this.fetchWithAccessToken(
            config.urlApi + 'accounts/' + customerConfig.AID + '/locations/?limit=100',
            {
                method: 'GET',
            },
            config, customerConfig
        ).then(function ( response: Response ) {
            return response.json();
        }).then(( json: any ) => {
            if (json.error === undefined) {
                json.sort(function ( a, b ) {
                    var nameA = a.name.toUpperCase();
                    var nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
                return json;
            } else {
                return null;
            }
        }).catch(function ( err ) {
            console.log('error getting stores', err);
            return null;
        });
    }

    static safeLocalStorageGet(key: string): string | undefined | void {
        try {
            return window.localStorage.getItem(key) || undefined;
        } catch (error) {
            console.error(error);
        }
    }

    static safeLocalStorageSet(key: string, value: string): void {
        try {
            window.localStorage.setItem(key, value);
        } catch (error) {
            console.error(error);
        }
    }
    static safeLocalStorageRemove(key: string): void {
        try {
            window.localStorage.removeItem(key);
        } catch (error) {
            console.error(error);
        }
    }

    static getMissingNewUserFields(user: any, customerConfig: AppConfig) {
        const missingFields: string[] = [];
        const fields = customerConfig.customer_fields;
        if (fields.first_name.required && !user.first_name) {
            missingFields.push('first_name');
        }
        if (fields.last_name.required && !user.first_name) {
            missingFields.push('last_name');
        }
        if (fields.phone_number.required && !user.phone_number) {
            missingFields.push('phone_number');
        }
        if (fields.email.required && !user.email) {
            missingFields.push('email');
        }
        if (fields.date_of_birth.required && !user.date_of_birth) {
            missingFields.push('date_of_birth');
        }
        if (fields.favorite_store.required && !user.favorite_store) {
            missingFields.push('favorite_store');
        }
        const userAddress = (user.addresses || [])[0] || {};
        if (fields.address_line.required && !userAddress.address_line) {
            missingFields.push('address_line');
        }
        if (fields.postal_place.required && !userAddress.postal_place) {
            missingFields.push('postal_place');
        }
        if (fields.postal_code.required && !userAddress.postal_code) {
            missingFields.push('postal_code');
        }
        if (fields.gender.required && !user.gender) {
            missingFields.push('gender');
        }
        if (fields.favorite_store.required && !user.favorite_store) {
            missingFields.push('favorite_store');
        }
        return missingFields;
    }

    static generateId() {
        const timestamp = new Date().getTime();
        const random = Math.random()
            .toString(36)
            .substring(7);
        return `${timestamp}-${random}`;
    }

    static displayTerms( event, terms: string ) {
        let x: any = window.open();
        x.document.open();
        x.document.write(
            terms +
            '<br/>' +
            '<a ' +
            'href="#" ' +
            'onclick="window.close();" ' +
            'style="background: #337ab7; border-radius:4px; padding:8px; color:#FFF; text-decoration: none;">' +
            'Lukk og gå tilbake' +
            '</a>' +
            '<br/><br/>' +
            '');
        x.document.close();
        event.preventDefault();
    }

    static findWebshopLink(coupon: Coupon) {
        return coupon.links && coupon.links.find(x => x.rel === 'webshop');
    }

    static suggestEmail(email?: string): string {
        const emailChk = EmailChk();

        return emailChk(email);
    }
}

export default Utils;
