import { ActionType } from '../actions/actions';
import State from '../domain/state/State';
import StateAndData from '../domain/StateAndData';

/* tslint:disable:cyclomatic-complexity */
const reducer = ( state: State, action ): State => {
    switch (action.type) {
        case ActionType.SetCustomer:
            return state.withCustomer(action.customer);
        case ActionType.GetAuth:
            return state.withIsAuthenticated(action.isAuthenticated);
        case ActionType.SetIsAuthenticated:
            return state.withIsAuthenticated(action.isAuthenticated);
        case ActionType.SetLoginPanelOpen:
            return state.withLoginPanelOpen(action.loginPanelOpen);
        case ActionType.SetRegisterPanelOpen:
            return state.withRegisterPanelOpen(action.registerPanelOpen);
        default:
            return state;
    }
};

export default ( stateAndData: StateAndData, action ): StateAndData => {
    return stateAndData.withState(reducer(stateAndData.state, action));
};
