/** Polyfills for newer versions of react-scripts based on Webpack 5+ */
import { Buffer } from "buffer";

window.Buffer = Buffer;

declare global {
  interface Window {
    Buffer: typeof Buffer;
  }
}
