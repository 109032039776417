import './polyfills'
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, Store } from 'redux';
import thunk from 'redux-thunk';
import 'bootstrap/dist/css/bootstrap.css';
import StateAndData from './domain/StateAndData';
import { default as reducers } from './reducers/reducers';
import State from './domain/state/State';

const stateAndData = new StateAndData(new State());
const store: Store<StateAndData> = createStore(
    reducers,
    stateAndData,
    applyMiddleware(thunk),
);

const BUILD_TARGETS = [
    {
        name: 'demo',
        path: './demo_brand/DemoApp',
        css: './demo_brand/demo.css',
        config: './demo_brand/DemoConfig',
    },
    {
        name: 'flisekompaniet',
        path: './flisekompaniet_brand/FlisekompanietApp',
        css: './flisekompaniet_brand/flisekompaniet.css',
        config: './flisekompaniet_brand/FlisekompanietConfig',
    },
];

const buildTarget = process.env.REACT_APP_BUILD_TARGET || 'demo';
const { path, css, config } = BUILD_TARGETS.find(
    ({ name }) => buildTarget === name,
) as any;

Promise.all([import(`${config}`), import(`${path}`), import(`${css}`)]).then(
    ([importedConfig, importedApp, _]) => {
        ReactDOM.render(
            <Provider store={store} key="provider">
                <importedApp.App config={importedConfig.default} />
            </Provider>,
            document.getElementById('root') as HTMLElement,
        );
    },
);
