export default class State {
    constructor( readonly customer: any = null,
                 readonly isAuthenticated: boolean = false,
                 readonly loginPanelOpen: boolean = false,
                 readonly registerPanelOpen: boolean = false ) {
    }

    withCustomer( customer: any ): State {
        return new State(
            customer,
            this.isAuthenticated,
            this.loginPanelOpen,
            this.registerPanelOpen,
        );
    }

    withIsAuthenticated( isAuthenticated: boolean ): State {
        return new State(
            this.customer,
            isAuthenticated,
            this.loginPanelOpen,
            this.registerPanelOpen,
        );
    }

    withLoginPanelOpen( loginPanelOpen: boolean ): State {
        return new State(
            this.customer,
            this.isAuthenticated,
            loginPanelOpen,
            this.registerPanelOpen,
        );
    }

    withRegisterPanelOpen( registerPanelOpen: boolean ): State {
        return new State(
            this.customer,
            this.isAuthenticated,
            this.loginPanelOpen,
            registerPanelOpen,
        );
    }
}
