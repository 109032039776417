import State from './state/State';

export default class StateAndData {
    constructor( readonly state: State ) {
    }

    withState( state: State ): StateAndData {
        return new StateAndData(state);
    }
}
