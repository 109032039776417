let config = {
    urlApi: process.env.REACT_APP_API_URL || 'https://api.dintero.com/v1/',
    audience_prefix: '',
    rulesLimit: parseInt(process.env.REACT_APP_RULES_LIMIT || '50', 10)
};
export type Config = typeof config;

config.audience_prefix = (
    process.env.REACT_APP_API_AUDIENCE_PREFIX || (config.urlApi + 'accounts/')
);

export default Object.freeze(Object.assign({}, config));
