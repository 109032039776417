import { AppConfig } from '../types';
import StateAndData from '../domain/StateAndData';
import Utils from '../utils/Utils';
import config from '../utils/config';

/* tslint:disable:object-literal-sort-keys */
export type GetState = () => StateAndData;

export enum ActionType {
    SetCustomer,
    GetAuth,
    SetIsAuthenticated,
    SetLoginPanelOpen,
    SetRegisterPanelOpen,
}

export type setCustomerT = ( customer: any ) => void;
export const setCustomer = ( customer: any ) => ( dispatch ) => {
    dispatch({
        customer,
        type: ActionType.SetCustomer
    });
};

export type isAuthValidT = () => boolean;
export const isAuthValid = () => ( dispatch ) => {
    const token = sessionStorage.getItem('customer_access_token');
    const isAuthenticated = token && Utils.validToken(token);

    dispatch({
        isAuthenticated,
        type: ActionType.SetIsAuthenticated
    });

    if (!isAuthenticated) {
        dispatch({
            customer: false,
            type: ActionType.SetCustomer
        });
    }

    return isAuthenticated;
};

export type getAuthT = (cfg: AppConfig) => void;
export const getAuth = (cfg: AppConfig) => ( dispatch ) => {
    const token = sessionStorage.getItem('customer_access_token');
    const isAuthenticated = token && Utils.validToken(token);

    dispatch({
        isAuthenticated,
        type: ActionType.SetIsAuthenticated
    });

    if (isAuthenticated) {
        fetch(config.urlApi + 'accounts/' + cfg.AID + '/customers/users/' + Utils.getCustomerIdFromAccessToken(), {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('customer_access_token'),
            })
        }).then(function ( response: Response ) {
            return response.json();
        }).then(( json: any ) => {
            let customer = null;
            if (json.error === undefined) {
                customer = json;
            }
            dispatch({
                customer,
                type: ActionType.SetCustomer
            });
        }).catch(function ( ex: JSON ) {
            console.log('parsing failed', ex);
        });
    } else {
        if (!isAuthenticated) {
            dispatch({
                customer: false,
                type: ActionType.SetCustomer
            });
        }
    }
};

export type setIsAuthenticatedT = ( isAuthenticated: boolean, cfg: AppConfig ) => void;
export const setIsAuthenticated = ( isAuthenticated: boolean, cfg: AppConfig ) => ( dispatch ) => {
    dispatch({
        isAuthenticated,
        type: ActionType.SetIsAuthenticated
    });

    if (isAuthenticated) {
        fetch(config.urlApi + 'accounts/' + cfg.AID + '/customers/users/' + Utils.getCustomerIdFromAccessToken(), {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('customer_access_token'),
            })
        }).then(function ( response: Response ) {
            return response.json();
        }).then(( json: any ) => {
            let customer = null;
            if (json.error === undefined) {
                customer = json;
            }
            dispatch({
                customer,
                type: ActionType.SetCustomer
            });
        }).catch(function ( ex: JSON ) {
            console.log('parsing failed', ex);
        });
    } else {
        dispatch({
            customer: false,
            type: ActionType.SetCustomer
        });
    }
};

export type setLoginPanelOpenT = (loginPanelOpen: boolean) => void;
export const setLoginPanelOpen = ( loginPanelOpen: boolean ) => ( dispatch ) => {
    dispatch({
        loginPanelOpen,
        type: ActionType.SetLoginPanelOpen
    });
};

export type setRegisterPanelOpenT = ( registerPanelOpen: boolean ) => void;
export const setRegisterPanelOpen = ( registerPanelOpen: boolean ) => ( dispatch ) => {
    dispatch({
        registerPanelOpen,
        type: ActionType.SetRegisterPanelOpen
    });
};
